import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import AniLink from "gatsby-plugin-transition-link/AniLink"

const ContactPage = () => (
  <>
    <SEO title="Contactez Uxo" />
    <div
    className="bg-violet min-h-screen">
        <div className="flex justify-center align-center pt-12">
           <p className="text-white">Contactez nous par <a className="text-rose" 
           href="mailto:talk@with-uxo.com"
           rel="noopener noreferrer">mail</a></p>
        </div>
    </div>
  </>
)

export default ContactPage
